<template>
  <v-hover #default="{ hover }">
    <v-text-field
      class="text-input-field"
      :class="classes"
      v-model="fieldValue"
      v-bind="$attrs"
      v-on="$listeners"
      flat
      solo
      :background-color="checkBg || focusField || hover || !!value ? inputBg : ''"
      @focus="changeFocus(true)"
      @blur="changeFocus(false)"
    >
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
      
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
    </v-text-field>
  </v-hover>
</template>

<script>
export default {
  name: 'FormField',
  inheritAttrs: false,
  props: {
    value: {
      type: String | Number,
      default: ''
    },
    checkBg: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: ''
    },
    customBg: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    focusField: false
  }),
  computed: {
    fieldValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    inputBg() {
      return this.customBg ? 'headerInputBg' : 'inputFocus'
    }
  },
  methods: {
    changeFocus(val) {
      this.focusField = val
    }
  }
}
</script>
